/*jshint esversion: 6 */
// console.log("JS works.");

var cursor=document.getElementById('cursor');
let cursorTO = null;

document.onmousemove = function(e){ 
  let x = e.clientX; 
  let y = e.clientY;
  cursor.style.top=y+'px';
  cursor.style.left=x+'px';
};

const loadImage = (url) => new Promise((resolve, reject) => {  
  const img = new Image();
  img.addEventListener('load', () => resolve(img));
  img.addEventListener('error', (err) => reject(err));
  img.src = url;
});

const targets = document.querySelectorAll("[data-cursor]");

const setCursor = (target) => {
  cursor.classList.remove("loading");
  cursor.style.setProperty('--cursorsize', "40vw");
  cursor.style.setProperty('--cursorimage', "url(" + target.dataset.cursor + ")");
  if(target.dataset.accent) cursor.style.setProperty('--accent', target.dataset.accent);
};

const resetCursor = () => {
  cursorTO = setTimeout(() => {
    unsetCursor();
  }, 2000);
};

const unsetCursor = ()=>{
  cursor.style.setProperty('--cursorsize', "0px");
  cursor.style.setProperty('--cursorimage', "");
  cursor.style.removeProperty('--accent');
  setTimeout(() => {
  }, 150);
};

targets.forEach(target => {
  target.addEventListener("mousemove", function(){
    clearTimeout(cursorTO);
    resetCursor();
    cursor.style.setProperty('--cursorsize', "40px");
    if(target.dataset.loaded == "true"){
      setCursor(target);
    } else {
      cursor.classList.add("loading");
      loadImage(target.dataset.cursor) 
        .then(img => {
          target.dataset.loaded = "true";
          setCursor(target);
        })
        .catch(err => console.error(err));
    }
  });
  target.addEventListener("mouseleave", function(){
    unsetCursor();
  });
});

// readmore
const readmores = document.querySelectorAll('.readmore');
readmores.forEach(readmore => {
  const target = document.querySelector(readmore.dataset.target);
  target.classList.add('readmore-hidden');
  readmore.dataset.isvisible = "nope";
  readmore.onclick = () => {
    if(readmore.dataset.isvisible == "yep"){
      console.log("hide text");
      readmore.textContent = readmore.dataset.showtext;
      readmore.dataset.isvisible = "nope";
    } else {
      console.log("show text");
      readmore.textContent = readmore.dataset.hidetext;
      readmore.dataset.isvisible = "yep";
    }
    target.classList.toggle('readmore-hidden');
  };
});


// pieces nav
const pieces = document.querySelectorAll(".event-piece");
// highlight nav item on scroll
const observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    let l = null;
    const id = entry.target.getAttribute('id');      
    if (entry.intersectionRatio > 0) {
      l = document.querySelector(`a[href="#${id}"]`);
      if(l)
        l.parentElement.classList.add('active');
    } else {
      l = document.querySelector(`a[href="#${id}"]`);
      if(l)
        l.parentElement.classList.remove('active');
    }
  });
});

// Track all sections that have an `id` applied
pieces.forEach((piece) => {
  observer.observe(piece);
});


// nav pluslink
const pluslink = document.querySelector("#pluslink");
const nav = document.querySelector("#nav");
pluslink.onclick = (e) => {
  e.preventDefault();
  nav.classList.toggle('opensocial');
};

// togglemenu
const togglemenu = document.querySelector("#togglemenu");

togglemenu.onclick = (e) => {
  e.preventDefault();
  document.body.classList.toggle('openmenu');
};

// mediations
const mediation_pausebutton = document.querySelector(".mediation-video-pause");
const mediation_video = document.querySelector("#mediation-video");
if(mediation_video){
  mediation_pausebutton.onclick = (e) => {
    e.preventDefault();
    if(mediation_video.paused){
      mediation_video.play(); 
    } else {
      mediation_video.pause();
    }
  };
}

const mediation_infos = document.querySelector(".mediation-cover-infos");
if(mediation_infos){
  mediation_infos.onclick = (e) => {
    e.preventDefault();
    mediation_infos.classList.toggle('active');
  };
}

// videos
const playerslinks = document.querySelectorAll('.video-player-link');
let currentplayer = null;
playerslinks.forEach((playerlink) => {
  playerlink.onclick = () => {
    const piecemedia = playerlink.nextElementSibling;
    if(piecemedia.classList.contains("visible")){
      piecemedia.classList.remove("visible");
      playerlink.classList.remove("active");
      if(currentplayer) currentplayer.pause();
    } else {
      piecemedia.classList.add("visible");
      playerlink.classList.add("active");
      const player = piecemedia.querySelector(".video-player");
      const myplayer = new Plyr(player);
      
      myplayer.on('ready', (event) => {
        if(currentplayer) currentplayer.pause();
        myplayer.play();
        currentplayer = myplayer;
      });      
    }    
  };
});

// video as gallery
var videogalleries = document.querySelectorAll('.gallery-video-player');
if(videogalleries.length){
  videogalleries.forEach(video => {
    video.addEventListener('click', function(){
      const myplayer = new Plyr(video, {
        // controls: ['play-large']
      });      
      myplayer.on('ready', (event) => {
        const player = event.detail.plyr;
        player.play();
      });
    }); 
  });
}


// filtering
var filternav = document.querySelector('.subsubnav.filter');
if(filternav){
  const filterslinks = filternav.querySelectorAll('a[data-filter]');
  let active = filternav.querySelector('.active');
  let filter = active.dataset.filter;
  let targets = document.querySelectorAll('.list-item');
  do_filtering(targets, filter);

  filterslinks.forEach((filterlink) => {
    filterlink.onclick = () => {
      filter = filterlink.dataset.filter;
      do_filtering(targets, filter);
      active = filternav.querySelector('.active');
      active.classList.remove('active');
      filterlink.classList.add('active');
    };
  });
}

function do_filtering(targets, filter){
  targets.forEach(target => {
    if(target.matches(filter)){
      target.classList.add('visible');
    } else {
      target.classList.remove('visible');
    }
  });
}
var artists = document.querySelectorAll('.list-item-artist-a');
if(artists){
  artists.forEach(artist => {
    artist.onclick = (e) => {
      e.preventDefault();
      artist.classList.add('loading');
      var url = artist.getAttribute("href");
      fetch(url).then(function (response) {
        return response.text();
      }).then(function (html) {
        var parser = new DOMParser();
        var doc = parser.parseFromString(html, 'text/html');
        var pieces = doc.querySelector('#artist-pieces');
        artist.classList.remove('loading');
        var active = document.querySelector('.artist-active');
        if(active){
          active.classList.remove('artist-active');
        }
        artist.classList.add('artist-active');
        artist.parentElement.nextElementSibling.innerHTML = pieces.innerHTML;
      }).catch(function (err) {
        // There was an error
        console.warn('Something went wrong.', err);
      });        
    };
  });
}


if (window.location.hash) {
  let hash = window.location.hash;
  if(hash == "#festival"){
    let main = document.querySelector('main');
    main.scrollIntoView({
      behavior:"smooth"
    });
  }
}